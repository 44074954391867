import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//do not remove these
String.prototype.title = function() {
    const arr = this.split(' ').map((sub)=>
      sub.charAt(0).toUpperCase() + sub.slice(1))
    return arr.join(' ')
}
String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
